import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useParams } from '@tanstack/react-router'
import { memo, type ReactElement } from 'react'
import { trpcReact } from '../../../../client'

const PIXEL_PER_DAY = 10
const INVOICE_WIDTH_PX = 300
const INVOICE_COLUMN_SPACING_PX = 5

import { amountToString, substractAmounts, zeroAmount, type Amount } from '@orus.eu/amount'
import type { InvoicingDebugInformation } from '@orus.eu/backend/src/invoicing/routers/invoices'
import type { InvoiceCreationParams } from '@orus.eu/backend/src/invoicing/services/invoice-lifecycle-service'
import type { InvoiceData } from '@orus.eu/backend/src/invoicing/stores/invoice-lifecycle-event'
import { formatYyyyMmDd, getCalendarDateFromTimestamp, PARIS } from '@orus.eu/calendar-date'
import { getAmountInvoiceScalableCostsTotal, type InvoiceDataV2, type InvoiceExplanation } from '@orus.eu/invoice'
import { getPeriodDurationInDays, periodIntersectsWith } from '@orus.eu/period'
import {
  colorTokens,
  ContentContainerBackoffice,
  Dialog,
  FlexColumn,
  spacing,
  Text,
  Tooltip,
  useDialogVisibility,
} from '@orus.eu/pharaoh'
import { DateTime } from 'luxon'
import { formatDate, formatDateTime } from '../../../../lib/format'
import { BackofficeSectionTitle } from '../../../atoms/backoffice-section-title'
export default function PlatformInvoicingPage(): ReactElement {
  const { subscriptionId } = useParams({ from: '/bak/contracts/$subscriptionId/invoicing' })

  const [debugInformation] = trpcReact.invoices.getInvoicingV2DebugInformation.useSuspenseQuery(subscriptionId)

  const blocks = useBlocks(debugInformation)
  return (
    <ContentContainerBackoffice>
      <BackofficeSectionTitle>Debug de la facturation</BackofficeSectionTitle>
      <Text>Cette page permet de visualiser les factures existantes et futures simulées selon la facturation v2.</Text>
      <TimelineBackground>
        {blocks.map((block) => (
          <InvoiceBlock key={block.invoiceId} block={block} />
        ))}
      </TimelineBackground>
    </ContentContainerBackoffice>
  )
}

const TimelineBackground = styled.div`
  position: relative;
`

const InvoiceBlock = memo<{ block: Block }>(function InvoiceBlock({ block }) {
  const {
    visible: isExplanationDialogVisible,
    show: showExplanationDialog,
    hide: hideExplanationDialog,
  } = useDialogVisibility(block.invoiceId)
  return (
    <div
      css={css`
        height: ${block.height}px;
        width: ${block.width}px;
        position: absolute;
        left: ${block.x}px;
        top: ${block.y}px;
      `}
      onClick={showExplanationDialog}
    >
      <Tooltip
        title={
          <FlexColumn>
            <Text>Issue date : {block.issueDate}</Text>
            <Text>Due date : {block.dueDate}</Text>
            <Text>
              {block.firstDay} - {block.lastDay} ({block.version})
            </Text>
            <Text>
              Scalable : {amountToString(block.scalableAmount)} €
              <br />
              Unscalable : {amountToString(block.unscalableAmount)} €
            </Text>
            {block.isAdjustment ? <Text>(Facture d’ajustement)</Text> : null}
          </FlexColumn>
        }
        arrowHorizontalPosition="left"
        arrowVerticalPosition="middle"
      >
        <div
          css={css`
            position: absolute;
            inset: 0;
          `}
        >
          <FlexColumn
            css={css`
              height: ${block.height}px;
              width: ${block.width}px;
              background-color: ${colorPerType[block.type]};
              justify-content: center;
              align-items: center;
              border: 1px solid white;
              padding: ${spacing[10]};
              overflow: hidden;
              position: absolute;
              inset: 0;
            `}
          >
            <Text>{amountToString(block.amount)} €</Text>
          </FlexColumn>
          <Text
            css={css`
              position: absolute;
              top: 0;
              right: 0;
            `}
            variant="caption"
          >
            {block.version}
          </Text>
        </div>
      </Tooltip>
      {isExplanationDialogVisible ? <InvoiceBlockDialog block={block} onClose={hideExplanationDialog} /> : null}
    </div>
  )
})

const InvoiceBlockDialog = memo<{ block: Block; onClose: () => void }>(function InvoiceBlockDialog({ block, onClose }) {
  return (
    <Dialog size="large" onClose={onClose} title="Explications">
      <div>
        <Text variant="body1">{explanationTypeIntro[block.explanation.type]}</Text>
        <Text variant="body1">
          <pre>{JSON.stringify(formatObject(block.explanation), null, 2)}</pre>
        </Text>
      </div>
    </Dialog>
  )
})

const explanationTypeIntro = {
  missing: 'Nous n’avons pas d’explication pour cette facture',
  specific: 'Ci-dessous, l’explication spécifique pour cette facture',
  target: 'Ci-dessous, l’explication pour la totalité du montant de la période',
}

const colorPerType = {
  'existing-invoice': colorTokens['color-bg-success-primary'],
  'future-invoice': colorTokens['color-bg-base-disable'],
  'skipped-adjustment-invoice': colorTokens['color-bg-danger-primary'],
}

type InvoiceBlockExplanation =
  | {
      /**
       * We don't have an explanation for this invoice
       */
      type: 'missing'
      theoreticalInvoices: string[]
    }
  | {
      /**
       * We have an exact explanation for the this specific invoice
       */
      type: 'specific'
      explanation: InvoiceExplanation
    }
  | {
      /**
       * We have an explanation for the total target amount for the time range of the invoice
       */
      type: 'target'
      explanation: InvoiceExplanation
    }

type InvoiceBlockData = {
  type: 'existing-invoice' | 'future-invoice' | 'skipped-adjustment-invoice'
  version: 'v1' | 'v2'
  invoiceId: string
  isAdjustment: boolean
  issueTimestamp: number
  dueTimestamp: number
  periodStartTimestamp: number
  periodEndTimestamp: number
  amount: Amount
  scalableAmount: Amount
  unscalableAmount: Amount
  explanation: InvoiceBlockExplanation
}

type Block = {
  invoiceId: string
  type: 'existing-invoice' | 'future-invoice' | 'skipped-adjustment-invoice'
  x: number
  y: number
  width: number
  height: number
  firstDay: string
  lastDay: string
  issueDate: string
  dueDate: string
  amount: Amount
  scalableAmount: Amount
  unscalableAmount: Amount
  version: 'v1' | 'v2'
  isAdjustment: boolean
  explanation: InvoiceBlockExplanation
}

type Column = {
  offset: number
  blocks: InvoiceBlockData[]
}

function useBlocks({
  existingInvoices,
  futureInvoices,
  skippedAdjustmentInvoices,
  theoreticalInvoices,
}: InvoicingDebugInformation): Block[] {
  const columns: Column[] = []

  function addToRelevantColumn(invoiceBlockData: InvoiceBlockData) {
    let column = columns.find(
      (column) => !column.blocks.some((blockData) => periodIntersectsWith(blockData, invoiceBlockData)),
    )
    if (!column) {
      column = { offset: columns.length, blocks: [] }
      columns.push(column)
    }
    column.blocks.push(invoiceBlockData)
  }

  for (const existingInvoice of existingInvoices) {
    addToRelevantColumn(
      getInvoiceBlockData(
        existingInvoice,
        existingInvoice.invoiceId,
        'existing-invoice',
        !('items' in existingInvoice) && !!existingInvoice.adjustedInvoiceId,
        theoreticalInvoices,
      ),
    )
  }

  for (const futureInvoice of futureInvoices) {
    addToRelevantColumn(
      getInvoiceBlockData(
        futureInvoice.invoiceData,
        futureInvoice.invoiceId,
        'future-invoice',
        futureInvoice.version === 2 && !!futureInvoice.adjustedInvoiceId,
        theoreticalInvoices,
      ),
    )
  }

  for (const skippedAdjustmentInvoice of skippedAdjustmentInvoices) {
    addToRelevantColumn(
      getInvoiceBlockData(
        skippedAdjustmentInvoice.invoiceData,
        skippedAdjustmentInvoice.invoiceId,
        'skipped-adjustment-invoice',
        true,
        theoreticalInvoices,
      ),
    )
  }
  return columns.flatMap((column) =>
    column.blocks.map((invoiceBlockData): Block => {
      const durationInDays = getPeriodDurationInDays(invoiceBlockData)
      const daysSinceFirstPeriodStart = getPeriodDurationInDays({
        periodStartTimestamp: columns[0].blocks[0].periodStartTimestamp,
        periodEndTimestamp: invoiceBlockData.periodStartTimestamp,
      })
      return {
        invoiceId: invoiceBlockData.invoiceId,
        type: invoiceBlockData.type,
        firstDay: formatDate(invoiceBlockData.periodStartTimestamp),
        lastDay: formatDate(
          DateTime.fromMillis(invoiceBlockData.periodEndTimestamp, { zone: PARIS }).minus({ days: 1 }).toMillis(),
        ),
        issueDate: formatDate(invoiceBlockData.issueTimestamp),
        dueDate: formatDate(invoiceBlockData.dueTimestamp),
        isAdjustment: invoiceBlockData.isAdjustment,
        x: column.offset * (INVOICE_WIDTH_PX + INVOICE_COLUMN_SPACING_PX),
        y: daysSinceFirstPeriodStart * PIXEL_PER_DAY,
        width: INVOICE_WIDTH_PX,
        height: durationInDays * PIXEL_PER_DAY,
        amount: invoiceBlockData.amount,
        scalableAmount: invoiceBlockData.scalableAmount,
        unscalableAmount: invoiceBlockData.unscalableAmount,
        version: invoiceBlockData.version,
        explanation: invoiceBlockData.explanation,
      }
    }),
  )
}

function getInvoiceBlockData(
  invoice: InvoiceData,
  invoiceId: string,
  type: 'existing-invoice' | 'future-invoice' | 'skipped-adjustment-invoice',
  isAdjustment: boolean,
  theoreticalInvoices: InvoiceCreationParams[],
): InvoiceBlockData {
  if ('items' in invoice) {
    const item = invoice.items[0]
    return {
      type,
      invoiceId,
      periodStartTimestamp: item.periodStartTimestamp,
      periodEndTimestamp: item.periodEndTimestamp,
      issueTimestamp: invoice.issueTimestamp,
      dueTimestamp: invoice.dueTimestamp,
      isAdjustment,
      amount: item.totalPremium,
      scalableAmount: zeroAmount,
      unscalableAmount: item.totalPremium,
      version: 'v1',
      explanation: { type: 'missing', theoreticalInvoices: formatTheoreticalInvoicesForDetails(theoreticalInvoices) },
    }
  }
  return {
    type,
    invoiceId,
    periodStartTimestamp: invoice.periodStartTimestamp,
    periodEndTimestamp: invoice.periodEndTimestamp,
    issueTimestamp: invoice.issueTimestamp,
    dueTimestamp: invoice.dueTimestamp,
    isAdjustment,
    amount: invoice.totalPremium,
    scalableAmount: getAmountInvoiceScalableCostsTotal(invoice),
    unscalableAmount: substractAmounts(invoice.totalPremium, getAmountInvoiceScalableCostsTotal(invoice)),
    version: 'v2',
    explanation: findBestExplanation(invoice, theoreticalInvoices),
  }
}

function findBestExplanation(
  invoice: InvoiceDataV2,
  theoreticalInvoices: InvoiceCreationParams[],
): InvoiceBlockExplanation {
  if (invoice.explanation) {
    return { type: 'specific', explanation: invoice.explanation }
  }

  const matchingTheoreticalInvoice = theoreticalInvoices.find(
    (theoreticalInvoice) =>
      !('items' in theoreticalInvoice.invoiceData) &&
      theoreticalInvoice.invoiceData.periodStartTimestamp === invoice.periodStartTimestamp &&
      theoreticalInvoice.invoiceData.periodEndTimestamp === invoice.periodEndTimestamp,
  )

  if (
    matchingTheoreticalInvoice &&
    'explanation' in matchingTheoreticalInvoice.invoiceData &&
    matchingTheoreticalInvoice.invoiceData.explanation
  ) {
    return { type: 'target', explanation: matchingTheoreticalInvoice.invoiceData.explanation }
  }

  return {
    type: 'missing',
    theoreticalInvoices: formatTheoreticalInvoicesForDetails(theoreticalInvoices),
  }
}

function formatTheoreticalInvoicesForDetails(theoreticalInvoices: InvoiceCreationParams[]): string[] {
  return theoreticalInvoices.map((t) => {
    const { periodStartTimestamp, periodEndTimestamp, totalPremium } =
      'items' in t.invoiceData ? t.invoiceData.items[0] : t.invoiceData

    return `${amountToString(totalPremium)} ${formatYyyyMmDd(
      getCalendarDateFromTimestamp(periodStartTimestamp, PARIS),
    )} -> ${formatYyyyMmDd(getCalendarDateFromTimestamp(periodEndTimestamp, PARIS))}`
  })
}

function formatObject(object: unknown): unknown {
  if (typeof object === 'object' && object !== null) {
    return Object.fromEntries(
      Object.entries(object).map(([key, value]) => {
        if (key.endsWith('Timestamp') && typeof value === 'number') {
          return [key, formatDateTime(value)]
        }
        return [key, formatObject(value)]
      }),
    )
  }
  return object
}
