import { addAmounts, type Amount, amountToNumber, multiplyByNumber, substractAmounts } from '@orus.eu/amount'
import type { InvoiceScalingTargetExplanation } from './invoice-explanation'
import type { InvoiceDataV2, InvoicingItemProductDetail } from './invoice-types'
import {
  getRcdaRepriseDuPasseExTaxAmountFromInvoice,
  getRcdaRepriseDuPasseTaxAmountFromInvoice,
} from './unscalable-costs'

export const unscalableCostTypes = [
  'terrorismTax',
  'partnerApplicationFee',
  'rcdaRepriseDuPasseExTax',
  'rcdaRepriseDuPasseTax',
] as const
export type UnscalableCostType = (typeof unscalableCostTypes)[number]

export type InvoiceScalingParameters<AMOUNT_TYPE> = {
  /**
   * Costs included in the total premium that should be scaled because they depend
   * on the coverage period duration, as opposed to the terrorism tax and the partner application fee,
   * which should be paid in full no matter the period duration.
   *
   * Exhaustive list of unscalable costs:
   * - terrorism tax
   * - partner application fee
   * - option "reprise du passé" of rcda
   *
   * Example of scalable costs (everything else):
   * - insurance taxes
   * - assistance VAT
   * - assistance premium without taxes
   * - orus installment fee
   * - orus management fee
   * - partner management fee
   */
  targetScalableCosts: AMOUNT_TYPE
  targetUnscalableCosts: Record<UnscalableCostType, AMOUNT_TYPE>
  targetExplanation: InvoiceScalingTargetExplanation
}

export function getAmountInvoiceScalableCostsTotal(invoiceData: ScalableInvoiceDataFields<Amount>): Amount {
  const unscalableCosts = addAmounts(
    invoiceData.partnerApplicationFee,
    invoiceData.terrorismTax,
    getRcdaRepriseDuPasseTaxAmountFromInvoice(invoiceData),
    getRcdaRepriseDuPasseExTaxAmountFromInvoice(invoiceData),
  )
  return substractAmounts(invoiceData.totalPremium, unscalableCosts)
}

export function getNumberInvoiceScalableCostsTotal(invoiceData: ScalableInvoiceDataFields<number>): number {
  const unscalableCosts =
    invoiceData.partnerApplicationFee +
    invoiceData.terrorismTax +
    amountToNumber(getRcdaRepriseDuPasseTaxAmountFromInvoice(invoiceData)) +
    amountToNumber(getRcdaRepriseDuPasseExTaxAmountFromInvoice(invoiceData))
  return invoiceData.totalPremium - unscalableCosts
}

/**
 * Scales the invoice data to meet the new total premium, while preserving the
 * relations between the different fields.
 * This API takes a target premium and not a scaling factor, to avoid instabilities
 * due to rounding errors.
 */
export function scaleInvoiceData(
  invoiceData: InvoiceDataV2,
  parameters: InvoiceScalingParameters<Amount>,
): InvoiceDataV2 {
  const { scaledFields, scalingFactor } = scaleAmountFields(invoiceData, parameters)
  return {
    ...invoiceData,
    ...scaledFields,
    breakdownByProduct: Object.fromEntries(
      Object.entries(invoiceData.breakdownByProduct).map(([product, productDetail]) => [
        product,
        scaleProductDetail(productDetail, scalingFactor),
      ]),
    ),
  }
}

/**
 * Same as scaleInvoiceData, but works with numbers.
 */
export function scaleInvoiceDataAccurately(
  invoiceData: InvoiceDataV2<number>,
  parameters: InvoiceScalingParameters<number>,
): InvoiceDataV2<number> {
  const { scaledFields, scalingFactor } = scaleAmountFieldsAccurately(invoiceData, parameters)
  return {
    ...invoiceData,
    ...scaledFields,
    breakdownByProduct: Object.fromEntries(
      Object.entries(invoiceData.breakdownByProduct).map(([product, productDetail]) => [
        product,
        scaleProductDetailAccurately(productDetail, scalingFactor),
      ]),
    ),
  }
}

export type ScalableInvoiceDataFields<AMOUNT_TYPE = Amount> = Pick<
  InvoiceDataV2<AMOUNT_TYPE>,
  | 'premiumWithoutTaxes'
  | 'insuranceTaxes'
  | 'terrorismTax'
  | 'assistanceVAT'
  | 'assistancePremiumWithoutTaxes'
  | 'totalPremium'
  | 'orusInstallmentFee'
  | 'orusManagementFee'
  | 'partnerManagementFee'
  | 'partnerApplicationFee'
  | 'quotes'
  | 'isFirstOfYear'
  | 'isInFirstYear'
  | 'explanation'
>

export function scaleAmountFields(
  invoiceData: ScalableInvoiceDataFields,
  { targetScalableCosts, targetUnscalableCosts, targetExplanation }: InvoiceScalingParameters<Amount>,
): { scaledFields: ScalableInvoiceDataFields; scalingFactor: number } {
  const scalableCostsTotal = getAmountInvoiceScalableCostsTotal(invoiceData)
  const scalingFactor = amountToNumber(targetScalableCosts) / amountToNumber(scalableCostsTotal)

  const targetUnscalableCostsTotal = addAmounts(
    targetUnscalableCosts.partnerApplicationFee,
    targetUnscalableCosts.rcdaRepriseDuPasseTax,
    targetUnscalableCosts.rcdaRepriseDuPasseExTax,
    targetUnscalableCosts.terrorismTax,
  )

  const newTotalPremium = addAmounts(targetScalableCosts, targetUnscalableCostsTotal)

  const scalableInsuranceTaxes = substractAmounts(
    invoiceData.insuranceTaxes,
    targetUnscalableCosts.rcdaRepriseDuPasseTax,
  )
  const newInsuranceTaxes = addAmounts(
    multiplyByNumber(scalableInsuranceTaxes, scalingFactor),
    targetUnscalableCosts.rcdaRepriseDuPasseTax,
  )

  const newPremiumWithoutTaxes = substractAmounts(
    newTotalPremium,
    addAmounts(newInsuranceTaxes, targetUnscalableCosts.terrorismTax),
  )
  return {
    scaledFields: {
      premiumWithoutTaxes: newPremiumWithoutTaxes,
      insuranceTaxes: newInsuranceTaxes,
      terrorismTax: targetUnscalableCosts.terrorismTax,
      assistanceVAT: multiplyByNumber(invoiceData.assistanceVAT, scalingFactor),
      assistancePremiumWithoutTaxes:
        invoiceData.assistancePremiumWithoutTaxes == undefined
          ? undefined
          : multiplyByNumber(invoiceData.assistancePremiumWithoutTaxes, scalingFactor),
      totalPremium: newTotalPremium, // computed to take into account both scalable and unscalable costs
      orusInstallmentFee: multiplyByNumber(invoiceData.orusInstallmentFee, scalingFactor),
      orusManagementFee: multiplyByNumber(invoiceData.orusManagementFee, scalingFactor),
      partnerManagementFee: multiplyByNumber(invoiceData.partnerManagementFee, scalingFactor),
      partnerApplicationFee: targetUnscalableCosts.partnerApplicationFee,
      quotes: invoiceData.quotes,
      isFirstOfYear: invoiceData.isFirstOfYear,
      isInFirstYear: invoiceData.isInFirstYear,
      explanation:
        scalingFactor === 1
          ? invoiceData.explanation
          : {
              type: 'scaling',
              targetScalableCosts: amountToNumber(targetScalableCosts),
              targetUnscalableCosts: amountToNumber(targetUnscalableCostsTotal),
              scalingFactor,
              targetExplanation,
            },
    },
    scalingFactor,
  }
}

export function scaleAmountFieldsAccurately(
  invoiceData: ScalableInvoiceDataFields<number>,
  { targetScalableCosts, targetUnscalableCosts, targetExplanation }: InvoiceScalingParameters<number>,
): { scaledFields: ScalableInvoiceDataFields<number>; scalingFactor: number } {
  const scalableCostsTotal = getNumberInvoiceScalableCostsTotal(invoiceData)
  const scalingFactor = targetScalableCosts / scalableCostsTotal

  const unscalableCostsTotal =
    targetUnscalableCosts.partnerApplicationFee +
    targetUnscalableCosts.rcdaRepriseDuPasseTax +
    targetUnscalableCosts.rcdaRepriseDuPasseExTax +
    targetUnscalableCosts.terrorismTax

  const newTotalPremium = targetScalableCosts + unscalableCostsTotal

  const scalableInsuranceTaxes = invoiceData.insuranceTaxes - targetUnscalableCosts.rcdaRepriseDuPasseTax
  const newInsuranceTaxes = scalableInsuranceTaxes * scalingFactor + targetUnscalableCosts.rcdaRepriseDuPasseTax

  const newPremiumWithoutTaxes = newTotalPremium - newInsuranceTaxes - targetUnscalableCosts.terrorismTax

  return {
    scaledFields: {
      premiumWithoutTaxes: newPremiumWithoutTaxes,
      insuranceTaxes: newInsuranceTaxes,
      terrorismTax: targetUnscalableCosts.terrorismTax, // unscalable amount
      assistanceVAT: invoiceData.assistanceVAT * scalingFactor,
      assistancePremiumWithoutTaxes:
        invoiceData.assistancePremiumWithoutTaxes == undefined
          ? undefined
          : invoiceData.assistancePremiumWithoutTaxes * scalingFactor,
      totalPremium: newTotalPremium, // computed to take into account both scalable and unscalable costs
      orusInstallmentFee: invoiceData.orusInstallmentFee * scalingFactor,
      orusManagementFee: invoiceData.orusManagementFee * scalingFactor,
      partnerManagementFee: invoiceData.partnerManagementFee * scalingFactor,
      partnerApplicationFee: targetUnscalableCosts.partnerApplicationFee, // unscalable amount
      quotes: invoiceData.quotes,
      isFirstOfYear: invoiceData.isFirstOfYear,
      isInFirstYear: invoiceData.isInFirstYear,
      explanation:
        scalingFactor === 1
          ? invoiceData.explanation
          : {
              type: 'scaling',
              targetScalableCosts: targetScalableCosts,
              targetUnscalableCosts: unscalableCostsTotal,
              scalingFactor,
              targetExplanation,
            },
    },
    scalingFactor,
  }
}

function scaleProductDetail(
  productDetail: InvoicingItemProductDetail | undefined,
  remainingFactor: number,
): InvoicingItemProductDetail | undefined {
  return productDetail
    ? {
        premiumWithoutTaxes: multiplyByNumber(productDetail.premiumWithoutTaxes, remainingFactor),
        insuranceTaxes: multiplyByNumber(productDetail.insuranceTaxes, remainingFactor),
        otherTaxes: multiplyByNumber(productDetail.otherTaxes, remainingFactor),
        orusFee: multiplyByNumber(productDetail.orusFee, remainingFactor),
        orusInstallmentFee: multiplyByNumber(productDetail.orusInstallmentFee, remainingFactor),
        orusManagementFee: multiplyByNumber(productDetail.orusManagementFee, remainingFactor),
        partnerManagementFee: multiplyByNumber(productDetail.partnerManagementFee, remainingFactor),
      }
    : undefined
}

function scaleProductDetailAccurately(
  productDetail: InvoicingItemProductDetail<number> | undefined,
  remainingFactor: number,
): InvoicingItemProductDetail<number> | undefined {
  return productDetail
    ? {
        premiumWithoutTaxes: productDetail.premiumWithoutTaxes * remainingFactor,
        insuranceTaxes: productDetail.insuranceTaxes * remainingFactor,
        otherTaxes: productDetail.otherTaxes * remainingFactor,
        orusFee: productDetail.orusFee * remainingFactor,
        orusInstallmentFee: productDetail.orusInstallmentFee * remainingFactor,
        orusManagementFee: productDetail.orusManagementFee * remainingFactor,
        partnerManagementFee: productDetail.partnerManagementFee * remainingFactor,
      }
    : undefined
}
